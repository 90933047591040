import { memo } from "react";
import styles from "./FeaturesCard.module.scss";

interface IFeaturesCardProps {
  icon: string;
  title: string;
  text: string;
  theme: 'black' | 'yellow'
}
export const FeaturesCard = memo((props: IFeaturesCardProps) => {
  const { icon, title, text, theme = 'black' } = props;

  return (
    <div className={`${styles.card} ${theme === 'yellow' && styles.yellow}`}>
      <img src={icon} alt={title} className={`${styles.icon} ${title==='Security Commitment' && styles.icon__big}`}/>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.text}>{text}</p>
    </div>
  );
});
