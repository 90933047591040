import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { FC, useEffect } from "react";
import styles from "./App.module.scss";
import { HomePageEN, HomePageFR } from "../../pages";
import { Header, Footer } from "../layout";
import { useAppSelector } from "../../services/hooks";
import { NotFound } from "../../pages/NotFound/NotFound";

export const App: FC = () => {
  const lang = useAppSelector((state) => state.lang.lang);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate(`/${lang}`);
    }
  }, [lang, location.pathname, navigate]);

  return (
    <div className={styles.app}>
      <div className={styles.container}>
        <Header />
        <div className={styles.grass}></div>
        <Routes>
          <Route path="/en" element={<HomePageEN />} />
          <Route path="/fr" element={<HomePageFR />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
};

export default App;
