import { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from "./Popup.module.scss";
import { dataPopup } from "./constants";
import { useAppSelector } from "../../services/hooks";
import { Notify, TStatusNotify } from "../Notify/Notify";

export type TPopup = {
  isOpen: boolean;
  setPopupOpen: (value: boolean) => void;
};

export const Popup: FC<TPopup> = ({ isOpen, setPopupOpen }) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const lang = useAppSelector((state) => state.lang.lang);
  const data = dataPopup[lang];
  const [openNotify, setOpenNotify] = useState<boolean>(false);
  const [notify, setNotify] = useState<{
    text: string;
    title: string;
    status: TStatusNotify;
  }>({
    text: "",
    title: "",
    status: "default",
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
  });

  const [formDataError, setFormDataError] = useState({
    name: false,
    email: false,
    message: false,
  });

  useEffect(() => {
    if (formData.name && formDataError.name) {
      setFormDataError({
        ...formDataError,
        name: false,
      });
    }
    if (formData.email && formDataError.email) {
      setFormDataError({
        ...formDataError,
        email: false,
      });
    }
    if (formData.message && formDataError.message) {
      setFormDataError({
        ...formDataError,
        message: false,
      });
    }
  }, [formData.email, formData.message, formData.name, formDataError]);
  const handleClickOutside = useCallback(
    (evt: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(evt.target as HTMLElement)
      ) {
        setFormDataError({
          name: false,
          email: false,
          message: false,
        });
        setPopupOpen(false);
      }
    },
    [setPopupOpen]
  );

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.message) {
      setFormDataError({
        name: !formData.name,
        email: !formData.email,
        message: !formData.message,
      });
    } else {
      setFormDataError({
        name: false,
        email: false,
        message: false,
      });
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL_BACKEND}/send-email-for-Africa`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );
        const result = await response.json();
        if (result.success) {
          //@ts-ignore
          setNotify(data.success);
          setOpenNotify(true);
          setFormData({
            name: "",
            email: "",
            company: "",
            message: "",
          });
        } else {
          throw new Error(result.error);
        }
      } catch (error) {
        //@ts-ignore
        setNotify(data.error);
        setOpenNotify(true);
      }
      setFormDataError({
        name: false,
        email: false,
        message: false,
      });
      setPopupOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <>
      <Notify
        status={notify.status}
        text={notify.text}
        title={notify.title}
        open={openNotify}
        setOpen={setOpenNotify}
      />
      <div className={`${styles.popup} ${isOpen && styles.popup_opened}`}>
        <div className={styles.popup__container} ref={popupRef}>
          <h5 className={styles.title}>{data.title}</h5>
          <form className={styles.popupForm} onSubmit={handleSubmit}>
            <label>
              <p className={styles.popupForm_text}>
                {data.name.text}
                <span className={styles.popupForm_span}>*</span>
              </p>
              <input
                value={formData.name}
                name="name"
                className={`${styles.popupForm_input} ${
                  formDataError.name && styles.error
                }`}
                type="text"
                placeholder={data.name.placeholder}
                onChange={handleChange}
              ></input>
              {formDataError.name && (
                <span className={styles.errorText}>{data.errorText}</span>
              )}
            </label>
            <label>
              <p className={styles.popupForm_text}>
                {data.email.text}
                <span className={styles.popupForm_span}>*</span>
              </p>
              <input
                value={formData.email}
                name="email"
                className={`${styles.popupForm_input} ${
                  formDataError.email && styles.error
                }`}
                type="email"
                placeholder={data.email.placeholder}
                onChange={handleChange}
                required
              ></input>
              {formDataError.email && (
                <span className={styles.errorText}>{data.errorText}</span>
              )}
            </label>
            <label>
              <p className={styles.popupForm_text}>{data.company.text}</p>
              <input
                value={formData.company}
                name="company"
                className={styles.popupForm_input}
                type="text"
                placeholder={data.company.placeholder}
                onChange={handleChange}
              ></input>
            </label>
            <label>
              <p className={styles.popupForm_text}>
                {data.message.text}
                <span className={styles.popupForm_span}>*</span>
              </p>
              <textarea
                value={formData.message}
                name="message"
                className={`${styles.popupForm_message} ${
                  formDataError.message && styles.error
                }`}
                placeholder={data.message.placeholder}
                onChange={handleChange}
              ></textarea>
              {formDataError.message && (
                <span className={styles.errorText}>{data.errorText}</span>
              )}
            </label>
            <button className={styles.button} type="submit">
              {data.buttonText}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
