import VisaIcon from "../../images/homepage/integration/visa_icon.svg";
import UnionPayIcon from "../../images/homepage/integration/unionpay_icon.svg";
import AmericanExpressIcon from "../../images/homepage/integration/american_express_icon.svg";
import ApplePayIcon from "../../images/homepage/integration/applepay_icon.svg";
import GooglePayIcon from "../../images/homepage/integration/googlepay_icon.svg";
import MasterCardIcon from "../../images/homepage/integration/mastercard_icon.svg";
import PayPalIcon from "../../images/homepage/integration/paypal_icon.svg";
import WeChatPayIcon from "../../images/homepage/integration/wechatpay_icon.svg";
import Circle from "../../images/homepage/integration/Circle.svg";
import AliPay from "../../images/homepage/integration/alipay_icon.svg";
import MPesa from "../../images/homepage/integration/m_pesa.svg";
import OPay from "../../images/homepage/integration/o_pay.svg";
import MBirr from "../../images/homepage/integration/m_birr.svg";
import Orange from "../../images/homepage/integration/orange.svg";
import MTN from "../../images/homepage/integration/mtn.svg";

import TasksIcon from "../../images/homepage/integration/tasks_icon.svg";
import UserIcon from "../../images/homepage/integration/user_icon.svg";
import StarIcon from "../../images/homepage/integration/star_icon.svg";

import SeamlessIcon from "../../images/homepage/features/seamless_transaction_icon.svg";
import CustomizableIcon from "../../images/homepage/features/customizable_checkout_icon.svg";
import PCIDSSIcon from "../../images/homepage/features/pci_dss_icon.svg";
import RealTimeIcon from "../../images/homepage/features/real_time_insights_icon.svg";
import ShieldIcon from "../../images/homepage/features/shield_icon.svg";
import RobustIcon from "../../images/homepage/features/robust_infrastructure_icon.svg";
import { LANG } from "../../types/lang";

export const info = {
  [LANG.EN]: [
    { id: 1, title: "45+", text: "African countries covered" },
    { id: 2, title: "1B+", text: "Population" },
    { id: 3, title: "50%+", text: "Internet penetration rate" },
    { id: 4, title: "50M+", text: "Сompanies and SMEs" },
  ],
  [LANG.FR]: [
    { id: 1, title: "45+", text: "Pays Africains couverts" },
    { id: 2, title: "1B+", text: "De Population" },
    { id: 3, title: "50%+", text: "Taux de pénétration de l'Internet" },
    { id: 4, title: "50M+", text: "De sociétés et de PMEs" },
  ],
};

export const introTitles = {
  [LANG.EN]: ["100+ payment methods", "Single integration", "Any industry"],
  [LANG.FR]: [
    "Plus de 100 méthodes de paiement",
    "Intégration unique",
    "Tout secteur d'activité",
  ],
};

export const benefits = {
  [LANG.EN]: [
    {
      id: "01",
      title: "Coverage Across Africa:",
      text: "Our comprehensive network covers the entire African continent, enabling your business to reach and serve every community",
    },
    {
      id: "02",
      title: "Diverse Payment Systems:",
      text: "We offer an extensive variety of payment options, ensuring smooth transactions for all your customers",
    },
    {
      id: "03",
      title: "Rapid Onboarding:",
      text: "Our platform's instant connection capabilities mean your business can start operating immediately",
    },
    {
      id: "04",
      title: "Tailored for All Sectors:",
      text: "From startups to established enterprises, our solutions are adaptable to any vertical or industry",
    },
  ],
  [LANG.FR]: [
    {
      id: "01",
      title: "Couverture dans toute l'Afrique:",
      text: "Notre réseau complet couvre l'ensemble du continent africain, ce qui permet à votre entreprise d'atteindre et de desservir toutes les communautés.",
    },
    {
      id: "02",
      title: "Diversité des systèmes de paiement:",
      text: "Nous offrons une grande variété d'options de paiement, garantissant des transactions fluides pour tous vos clients.",
    },
    {
      id: "03",
      title: "Onboarding rapide:",
      text: "Grâce aux capacités de connexion instantanée de notre plateforme, votre projet peut commencer à fonctionner immédiatement.",
    },
    {
      id: "04",
      title: "Adapté à tous les secteurs:",
      text: "Des startups aux entreprises établies, nos solutions s'adaptent à tout secteur d'activité.",
    },
  ],
};

export const integration_icons = [
  {
    name: "visa",
    icon: VisaIcon,
  },
  {
    name: "mastercard",
    icon: MasterCardIcon,
  },
  {
    name: "american_express",
    icon: AmericanExpressIcon,
  },
  {
    name: "paypal",
    icon: PayPalIcon,
  },
  {
    name: "Circle",
    icon: Circle,
  },
  {
    name: "googlepay",
    icon: GooglePayIcon,
  },
  {
    name: "applepay",
    icon: ApplePayIcon,
  },
  {
    name: "unionpay",
    icon: UnionPayIcon,
  },
  {
    name: "AliPay",
    icon: AliPay,
  },
  {
    name: "MTN",
    icon: MTN,
  },
  {
    name: "wechatpay",
    icon: WeChatPayIcon,
  },
  {
    name: "MPesa",
    icon: MPesa,
  },
  {
    name: "OPay",
    icon: OPay,
  },
  {
    name: "MBirr",
    icon: MBirr,
  },
  {
    name: "Orange",
    icon: Orange,
  },
];

export const integration_info = {
  [LANG.EN]: [
    {
      text: `2K+\nProjects`,
      icon: TasksIcon,
      hasBg: true,
    },
    {
      text: `4.8\nSatisfaction`,
      icon: StarIcon,
      hasBg: false,
    },
    {
      text: `18\u00A0Years\nOf\u00A0Success `,
      icon: UserIcon,
      hasBg: true,
    },
  ],
  [LANG.FR]: [
    {
      text: `Plus\u00A0de\u00A02000\nProjets`,
      icon: TasksIcon,
      hasBg: true,
    },
    {
      text: `4.8\nSatisfaction`,
      icon: StarIcon,
      hasBg: false,
    },
    {
      text: `De\u00A0Succès\n18 Ans`,
      icon: UserIcon,
      hasBg: true,
    },
  ],
};

export const features = {
  [LANG.EN]: [
  {
    title: "Seamless Transactions",
    text: `Accept payments in any currency and integrate with various payment methods, ensuring a smooth user experience`,
    icon: SeamlessIcon,
  },
  {
    title: "Real-Time Insights",
    text: `Use our dashboard for instant access to order volumes, transaction counts, and live revenue updates`,
    icon: RealTimeIcon,
  },
  {
    title: "Customizable Checkout",
    text: `Personalize your payment pages with your brand’s logos, colors, and design elements`,
    icon: CustomizableIcon,
  },
  {
    title: "Advanced Fraud Protection",
    text: `Benefit from 24/7 monitoring and sophisticated fraud detection to safeguard your transactions`,
    icon: ShieldIcon,
  },
  {
    title: "Robust Infrastructure",
    text: `Rely on our high-speed, redundant systems with dual data centers for maximum uptime`,
    icon: RobustIcon,
  },
  {
    title: "Security Commitment",
    text: `Operate within a PCI-DSS Level 1 certified environment for top-tier data protection`,
    icon: PCIDSSIcon,
  },
],
[LANG.FR]: [
  {
    title: "Transactions sans faille",
    text: `Accepter les paiements dans n'importe quelle devise et s'intégrer à diverses méthodes de paiement, afin de garantir une expérience fluide pour l'utilisateur`,
    icon: SeamlessIcon,
  },
  {
    title: "Informations en temps réel",
    text: `Utilisez notre tableau de bord pour un accès instantané aux volumes de commandes, au nombre de transactions et aux mises à jour des revenus en temps réel`,
    icon: RealTimeIcon,
  },
  {
    title: "Paiement personnalisable",
    text: `Personnalisez vos pages de paiement avec les logos, les couleurs et les éléments de conception de votre marque`,
    icon: CustomizableIcon,
  },
  {
    title: "Protection anti-fraude avancée",
    text: `Bénéficiez d'une surveillance 24/7 et d'une détection sophistiquée des fraudes pour protéger vos transactions`,
    icon: ShieldIcon,
  },
  {
    title: "Infrastructure robuste",
    text: `Faites confiance à nos systèmes redondants à grande vitesse et à nos doubles data centres pour une disponibilité maximale`,
    icon: RobustIcon,
  },
  {
    title: "Engagement de sécurité",
    text: `Fonctionner dans un environnement certifié PCI-DSS niveau 1 pour la protection des données haut de gamme`,
    icon: PCIDSSIcon,
  },
]

};
