import { FC, useEffect, useState } from "react";
import styles from "./Footer.module.scss";
import logo from "../../../images/logo.svg";
import psi from "../../../images/footer/pci.svg";
import switzerland from "../../../images/switzerland_flag.svg";
import { useAppSelector } from "../../../services/hooks";
import { dataFooter } from "./constants";

export const Footer: FC = () => {
  const lang = useAppSelector((state) => state.lang.lang);
  const [content, setContent] = useState(dataFooter[lang]);

  useEffect(() => {
    setContent(dataFooter[lang]);
  }, [lang]);
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.text}>{content.text}</div>
      </div>

      <div className={styles.line}>
        <div className={styles.marquee}>
          <img src={switzerland} alt="switzerland" className={styles.line__logo}/>{content.line}
        </div>
      </div>

      <footer className={`${styles.wrapper} ${styles.footer}`}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <nav>
          <ul className={styles.nav}>
            {content.nav.map(({ name, link }, index) => (
              <>
                {index !== 0 ? <span className={styles.footer__line}>|</span> : ""}
                <li key={index+name}>
                  <a target="_blank" href={`${link}/${lang}`} className={styles.nav__link} rel="noreferrer">
                    {name}
                  </a>
                </li>
              </>
            ))}
          </ul>

          <p className={styles.copyright}>
            {content.copyright}{""}
            <img src={psi} alt="PSI" className={styles.copyright__img} />
          </p>
        </nav>
        <ul className={styles.social}>
          {content.social.map(({ name, link, icon }) => (
            <li key={name}>
              <a target="_blank" href={link} className={styles.social__item} rel="noreferrer">
                <img src={icon} alt={name} />
              </a>
            </li>
          ))}
        </ul>
      </footer>
    </div>
  );
};
