import { FC, useState } from "react";
import styles from './HomePage.module.scss'
import { Popup, Button, Benefit, IntegrationItem, FeaturesCard, ButtonContact } from "../../components";
import { routes } from "../../components/layout/Header/constant";
import { introTitles, benefits, integration_icons, integration_info, features, info } from "./constants";
import MapFlags from "../../images/homepage/flags/map_flags.png";
import AfricaMapWaves from "../../images/homepage/intro/africa_map_waves.svg";
import BirdsRight from "../../images/homepage/intro/birds_right.svg";
import Tree from "../../images/homepage/intro/tree.svg";
import ComputerIcon from "../../images/homepage/integration/computer.svg";
import Wrap from "../../images/tree_left.png";
import { LANG } from "../../types/lang";

export const HomePageEN: FC = () => {
  const [open, setOpen] = useState(false);
  const LANGUAGE = LANG.EN;

 
  return (
    <div>
      <Popup isOpen={open} setPopupOpen={setOpen} />

      <section
        className={styles.container__intro}
        id={routes.where}
      >
        <div className={styles.intro}>
          <h1 className={styles.intro__title}>We cover all of Africa</h1>
          {info[LANGUAGE].map(({ id, text, title }) => (
            <div
              key={id}
              className={`${styles.benefit} ${styles[`benefit_${id}`]}`}
            >
              <h2 className={styles.benefit__title}>{title}</h2>
              <p className={styles.benefit__text}>{text}</p>
            </div>
          ))}

          <div className={styles.intro__columns}>
            <div>
              <h4 className={styles.intro__subtitle}>
                Mandato Global Gateway: Empowering African Connectivity
              </h4>
              <p className={styles.intro__text}>
                Step into the future of business with Mandato Global Gateway. We
                provide unique access to the African market, offering a broad
                spectrum of payment methods and instant integration tailored for
                any industry
              </p>
              <div className={styles.intro__button}>
                <Button onClick={() => setOpen(true)}>Contact Us</Button>
              </div>
            </div>
            <div className={styles.intro__columns_2}>
              {introTitles[LANGUAGE].map((title) => (
                <h3 key={title} className={styles.intro__columns_title}>
                  {title}
                </h3>
              ))}
            </div>
          </div>
          <img src={MapFlags} alt="Africa Map" className={styles.intro__bg} />
          <img
            src={AfricaMapWaves}
            alt="Wases"
            className={styles.intro__bg_waves}
          />
          <img
            src={BirdsRight}
            alt="Wases"
            className={styles.intro__bg_birds}
          />
          <img src={Tree} alt="Tree" className={styles.intro__bg_tree} />
        </div>
      </section>

      <section
        className={styles.container__benefits}
        id={routes.why}
      >
        <div className={styles.benefits}>
    
            <span className={styles.benefits__subtitle}>Why Choose Us:</span>
  
          <div className={styles.benefits__header}>
            <h2 className={styles.benefits__title}>
              Mandato Global Gateway: Seamlessly Connecting Businesses Across
              Africa
            </h2>
      
              <h2 className={styles.benefits__block}>
                Join us and discover the limitless potential of a truly
                connected continent
              </h2>
   
          </div>
          <ul className={styles.benefits__list}>
            {benefits[LANGUAGE].map((benefit) => (
              <li key={benefit.id} className={styles.benefits__item}>
                <Benefit {...benefit} />
              </li>
            ))}
          </ul>
        </div>
        <img src={Wrap} className={styles.benefits__wrap} alt="Tree" />
      </section>

      <section className={styles.container__integration}>
        <div className={styles.integration}>
          <div>
            <div className={styles.integration__box}>
              <h2 className={styles.integration__title}>
                All-in-One Integration:
              </h2>
              <ul className={styles.integration__list}>
                {integration_icons.map(({ name, icon }) => (
                  <li key={name} className={styles.integration__service}>
                    <img
                      src={icon}
                      alt={name}
                      className={styles.integration__icon}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <h3 className={styles.integration__subtitle}>
              ...and dozens of other cards and mobile wallets!
            </h3>
          </div>
          <div className={styles.integration__column_2}>
            <img
              src={ComputerIcon}
              className={styles.integration__img}
              alt="computer"
            />
            <ul>
              {integration_info[LANGUAGE].map((item, index) => (
                <li
                  key={item.text}
                  className={styles[`integration__item_${index}`]}
                >
                  <IntegrationItem {...item} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className={styles.features} id={routes.how}>
        <h2 className={styles.features__title}>Innovative Features</h2>
        <ul className={styles.features__list}>
          {features[LANGUAGE].map((feature, index) => (
            <li
              key={feature.title}
              className={`${styles.features__item} ${
                index % 2 === 0 && styles.yellow
              }`}
            >
              <FeaturesCard
                {...feature}
                theme={index % 2 === 0 ? "yellow" : "black"}
              />
            </li>
          ))}
        </ul>
      </section>

      <section id={routes.getInTouch}>
        <ButtonContact title={<span>Get in touch for<br/>any additional info</span>} text={"The complete onboarding takes just a few days"} buttonText={"Contact"}/>
      </section>
    </div>
  );
};
