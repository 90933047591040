export const routes = {
  where: "where",
  why: "why",
  how: "how",
  getInTouch: "getInTouch",
};

export const routesEN = [
  {
    name: "Where",
    path: routes.where,
  },
  {
    name: "Why",
    path: routes.why,
  },
  {
    name: "How",
    path: routes.how,
  },
  {
    name: "Get In Touch",
    path: routes.getInTouch,
  },
];

export const routesFR = [
  {
    name: "Où",
    path: routes.where,
  },
  {
    name: "Pourquoi",
    path: routes.why,
  },
  {
    name: "Comment",
    path: routes.how,
  },
  {
    name: "Nous contacter",
    path: routes.getInTouch,
  },
];
