import { LANG } from "../../types/lang";

export const dataPopup = {
  [LANG.EN]: {
    title: "The complete onboarding takes just a few days",
    name: { text: "Name", placeholder: "Insert Your Name" },
    email: { text: "E-mail Address", placeholder: "Insert Your E-mail Here" },
    company: { text: "Company", placeholder: "Insert Your Company Name" },
    message: { text: "Message", placeholder: "Insert Your Message" },
    response: {
      success: "Your application has been sent!",
      error: "An error has occurred. Please try again.",
    },
    buttonText: "Send form",
    errorText: "Required field",
    success: {
      title: "Success",
      text: "Email sent successfully!",
      status: "success"
    },
    error: {
      title: "Error",
      text: "Failed to send email",
      status: "error"
    },
  },
  [LANG.FR]: {
    title: `L'onboarding complet ne prend que quelques jours`,
    name: { text: "Nom", placeholder: "Indiquez votre nom" },
    email: {
      text: "Adresse e-mail",
      placeholder: "Indiquez votre adresse e-mail",
    },
    company: {
      text: "Entreprise",
      placeholder: "Indiquez le nom de votre entreprise",
    },
    message: { text: "Message", placeholder: "Entrez votre message" },
    response: {
      success: "Votre candidature a été envoyée!",
      error: "Une erreur est survenue. Essayer à nouveau",
    },
    buttonText: "Envoyer",
    errorText: "Champ requis",
    success: {
      title: "Succès",
      text: "Email envoyé avec succès !",
      status: "success"
    },
    error: {
      title: "Erreur",
      text: "Échec de l'envoi de l'email",
      status: "error"
    },
  },
};
