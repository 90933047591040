import { memo } from "react";
import styles from "./IntegrationItem.module.scss";

interface IIntegrationItemProps {
  icon: string;
  text: string;
  hasBg?: boolean;
}
export const IntegrationItem = memo((props: IIntegrationItemProps) => {
  const { icon, text, hasBg } = props;

  return (
    <div className={styles.integration}>
      <div className={`${styles.icon} ${hasBg && styles.bg}`}>
      <img src={icon} alt={text} className={`${styles.img} ${!hasBg && styles.img__big}`}/>
      </div>
      <p className={styles.text}>{text}</p>
    </div>
  );
});
