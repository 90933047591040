import { FC, useEffect, useState } from "react";
import styles from "./Header.module.scss";
import logo from "../../../images/logo.svg";
import { routesEN, routesFR } from "./constant";
import { DropdownLang } from "../../DropdownLang/DropdownLang";
import { useAppSelector } from "../../../services/hooks";
import { LANG } from "../../../types/lang";

export const Header: FC = () => {
  const [routes, setRoutes] = useState(routesEN);
  const { lang } = useAppSelector((state) => state.lang);

  useEffect(() => {
    if (lang === LANG.FR) {
      setRoutes(routesFR);
    } else {
      setRoutes(routesEN);
    }
  }, [lang]);

  return (
    <div className={styles.header}>
      <div className={styles.nav}>
        <img className={styles.logo} src={logo} alt="Логотип" />
        <ul className={styles.routes}>
          {routes.map((item, index) => (
            <li className={styles.routes_item} key={index}>
              <a href={'#'+item.path} className={styles.link}>
                {item.name}
              </a>
            </li>
          ))}
        </ul>
        <DropdownLang />
      </div>
    </div>
  );
};
