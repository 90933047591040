import { FC, ReactNode, useState } from "react";
import styles from "./ButtonContact.module.scss";
import birds from "../../images/Birds_vector.svg";
import { Popup } from "../Popup/Popup";
import world from "../../images/footer/world.svg";
import location from "../../images/footer/location.svg";

interface IButtonContactProps {
  title: ReactNode;
  text: string;
  buttonText: string;
}

export const ButtonContact: FC<IButtonContactProps> = ({
  title,
  text,
  buttonText,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.contact}>
        <div className={styles.component}>
          <ul className={styles.contacts}>
            <li className={styles.contacts__item}>
              <img src={world} alt="website" className={styles.contacts__img} />
              <a
                target="_blank"
                className={`${styles.contacts__text} ${styles.contacts__link}`}
                href={`${process.env.REACT_APP_URL_GLOBAL_MANDATO}/en`}
                rel="noreferrer"
              >
                www.mandato.global
              </a>
            </li>
            <li className={styles.contacts__item}>
              <img
                src={location}
                alt="location"
                className={styles.contacts__img}
              />
              <span className={styles.contacts__text}>
                Zugerstrasse 46, 6314 Unterägeri, Switzerland
              </span>
            </li>
          </ul>
          <div className={styles.info}>
            <div className={styles.container}>
              <p className={styles.component_text}>{title}</p>
              <p className={styles.component_item}>{text}</p>
            </div>
            <button className={styles.button} onClick={() => setOpen(true)}>
              {buttonText}
            </button>
          </div>
        </div>
      </div>
      <img src={birds} className={styles.birds} alt="Птицы" />
      <Popup isOpen={open} setPopupOpen={setOpen} />
    </div>
  );
};
