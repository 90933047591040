import { memo } from "react";
import styles from "./Benefit.module.scss";

interface IBenefitProps {
  id: string;
  title: string;
  text: string;
}
export const Benefit = memo((props: IBenefitProps) => {
  const { id, title, text } = props;

  return (
    <div className={styles.benefit}>
        <span className={styles.index}>{id}</span>
      <h5 className={styles.title}>{title}</h5>
      <p className={styles.text}>{text}</p>
    </div>
  );
});
